class Speed {
    constructor(road, redrawFum) {
        this.road = road

        this.redrawFum = redrawFum

        this.canvas = road.canvas
        this.context = road.context

        this.msg = {
            baseSpeed: 4,
            speed: 5,
            baseLong: 100,
            minDistance: 100,
            pointerNum: 4,
        }

        this.speedList = []
    }

    setSpeed() {
        const roadMsg = this.road.msg
        const speedLineNum = this.msg.pointerNum
        let list = this.speedList

        if (list && list.length < speedLineNum) {
            if (list.length == 0) {
                list.unshift(this._createdSpeedPointer())
            } else if (list[0].y >= list[0].nextDistance + roadMsg.top) {
                list.unshift(this._createdSpeedPointer())
            }
        } else if (list[list.length - 1].y >= this.canvas.height) {
            list.pop()
        }
        list.forEach(one => {
            one.y += this.msg.baseSpeed
            let obj = {
                y1: one.y,
                x1: one.fx.getX(one.y),
                y2: one.y + this._getSpeed(one.y),
                x2: one.fx.getX(one.y + this._getSpeed(one.y)),
                color: one.color
            }
            this.drawSpeed(obj)
        })
    }

    drawSpeed(one) {
        let obj = {
            color: '#fff',
            ...one,
        }
        this._line(obj, 1)
    }



    _fx({ lineOption, a, b }) {
        if (lineOption) {
            let line
            if (typeof lineOption == 'object') {
                line = lineOption
            } else if (typeof lineOption == 'number') {
                line = this.lines[lineOption]
            }
            let { y2, x2, y1, x1 } = line
            if (a == undefined) {
                a = (y2 - y1) / (x2 - x1)
            }
            if (b) {
                let nb = y2 - a * x2
                b = nb + b
            } else {
                b = y2 - a * x2
            }
        }
        return new CreatedLine(a, b)
    }

    _getSpeed(y) {
        return y / this.canvas.height * this.msg.baseLong
    }

    _createdSpeedPointer() {
        const msg = this.road.msg
        const routerWidth = this.road.msg.routerWidth

        let step = Math.stochastic(-routerWidth.top, routerWidth.top)

        let lineObj = {
            x1: msg.centerW + step,
            x2: msg.centerW + step * (routerWidth.bottom / routerWidth.top),
            y1: msg.top,
            y2: msg.height
        }

        let { y2, x2, y1, x1 } = lineObj

        let a = (y2 - y1) / (x2 - x1)
        let b = y2 - a * x2

        let obj = {
            y: msg.top,
            fx: this._fx({ a, b }),
            color: `rgba(125,125,125,${Math.stochastic(0,.5)})`,
            nextDistance: Math.stochastic(this.msg.minDistance, this.msg.minDistance + 100)
        }
        return obj;
    }

    _line({ x1, y1, x2, y2, color }, d = 2) {
        this.context.save()
        this.context.beginPath()
        this.context.moveTo(x1 - d, y1)
        this.context.lineTo(x2 - d, y2)
        this.context.lineTo(x2 + d, y2)
        this.context.lineTo(x1 + d, y1)
        if (color) {
            this.context.fillStyle = color
        }
        this.context.fill()
        this.context.restore()
    }
}


class CreatedLine {
    constructor(a, b) {
        this.a = a;
        this.b = b;
    }
    getX(y) {
        return (y - this.b) / this.a
    }
    getY(x) {
        return this.a * x + this.b
    }
}

export default Speed
