class Map {
    constructor(road,option){
        this.img = null
        this.url = require('@/assets/center_v2_map.png')

        this.road = road

        this.canvas = road.canvas
        this.context = road.context

        this.msg = new Proxy({},{
            get:(obj,prop)=>{
                return this.road.msg[prop]
            }
        })

        if(option){
            if(option.url){
                this.url = option.url
            }
        }
    }

    drawMap(){
        return new Promise(resolve=>{
            if(this.img){
                this.drawFun()
                resolve()
            }else{
                this.imgLoad(this.url).then((img)=>{
                    this.img = img
                    this.drawFun()
                    resolve()
                }).catch((err)=>{
                    console.log('图片加载失败',err)
                })
            }
        })
    }

    drawFun(){
        let msg = this.msg

        let ch = this.canvas.height
        let iw = this.img.width,
            ih = this.img.height
        let dw = (msg.centerW - msg.routerWidth.bottom) * 1.1,
            dh = ih/iw * dw

        let x = 0,
            y = ch - dh

        let el = this.img
        this.context.drawImage(el, x, y, dw, dh)
    }

    imgLoad(url){
        return new Promise((resolve,reject)=>{
            let img = new Image()
            img.onload = ()=>{
                resolve(img)
            }
            img.onerror = (err)=>{
                console.log(err)
                reject()
            }
            img.src = url
        })
    }
}

export default Map