import Utils from './Utils.js'

class Car extends Utils {
    constructor(canvas, { earth, road }) {
        super()
        this.canvas = canvas
        this.context = canvas.getContext('2d')

        this.earth = earth
        this.road = road

        this.baseCarUrl = require('@/assets/v2-1/center_v2_1_car.png')
        this.baseCarImg = null

        this.appPositionUrl = require('@/assets/v2-1/center_v2_1_car_position.png')
        this.appPositionImg = null

        this.empennageUrl = require('@/assets/v2-1/center_v2_1_end.png')
        this.empennageImg = null

        this.position = []
        this.subPosition = []

        this.widthScale = 0.63

    }

    setWidthScale(val, callback) {
        let xl = this.canvas.width
        let yl = this.canvas.height

        this.widthScale = Number(val)
        this.context.clearRect(0, 0, xl, yl)
        this.getPostion(callback)
    }

    imgLoad(url) {
        return new Promise((resolve, reject) => {
            let img = new Image()
            img.onload = () => {
                resolve(img)
            }
            img.onerror = (err) => {
                console.log(err)
                reject()
            }
            img.src = url
        })
    }

    getPostion(callback) {
        this.drawCar('appPosition', () => {
            let imgData = this.context.getImageData(0, 0, this.canvas.width, this.canvas.height)
            let data = imgData.data

            let xl = this.canvas.width
            let yl = this.canvas.height


            let l = []
            let subList = []
            for (let y = 0; y < yl; y++) {
                for (let x = 0; x < xl; x++) {
                    let ind = (y * xl + x) * 4;
                    let r = data[ind];
                    let g = data[ind + 1];
                    let b = data[ind + 2];
                    // let a = data[ind + 3];

                    if (r != g || r != b || g != b) {
                        if (subList.length == 0) {
                            subList.push({ x, y })
                        } else {
                            let has = subList.some(one => {
                                if (x - one.x < 10 && y - one.y < 10) {
                                    return true
                                } else {
                                    return false
                                }
                            })
                            if (!has) {
                                subList.push({ x, y })
                            }
                        }
                    }

                    if ((r == g && g == b) && r == 255) {
                        if (l.length == 0) {
                            l.push({ x, y })
                        } else {
                            let has = l.some(one => {
                                if (x - one.x < 10 && y - one.y < 10) {
                                    return true
                                } else {
                                    return false
                                }
                            })
                            if (!has) {
                                l.push({ x, y })
                            }
                        }
                    }

                }
            }
            this.position = l
            this.subPosition = subList.sort((a, b) => {
                return a.x - b.x
            })
            this.context.clearRect(0, 0, xl, yl)
            callback ? callback() : this.drawCar()
        })
    }

    drawCar(type = 'baseCar', callback) {
        if (this[type + 'Img']) {
            this.drawCarFun(type)
            callback && callback()
        } else {
            this.imgLoad(this[type + 'Url']).then(img => {
                this[type + 'Img'] = img
                this.drawCarFun(type)
                callback && callback()
            })
        }
    }

    drawCarFun(type = 'baseCar') {
        let baseSscale = this.widthScale * this._scaleByHeight()
        let scale = baseSscale > .8 ? .8 : baseSscale

        let el = this[type + 'Img']

        let ch = this.canvas.height,
            cw = this.canvas.width
        let iw = el.width,
            ih = el.height
        let dw = cw * scale,
            dh = ih / iw * dw

        let x = (cw - dw) / 2 + 1,
            y = (ch - dh) / 3 * (2 * scale / baseSscale)

        this.context.drawImage(el, x, y, dw, dh)
    }

    showEmpennage() {
        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)
        this.drawCar()
        this.drawCar('empennage')
    }

    hiddenEmpennage() {
        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)
        this.drawCar()
    }
}

export default Car
