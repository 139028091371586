import Utils from './Utils.js'
class Services extends Utils {
    constructor(canvas, earth) {
        super()
        this.earth = earth
        this.canvas = canvas
        this.context = canvas.getContext('2d')

        this.services = []

        this.init()

        this.drawing=0

        this.serviceWith= 55
    }

    init(){
        this.x = this.earth.x
        this.y = this.earth.y
        this.r = this.earth.r * 1.03
    }

    setServiceWith(val){
        this.serviceWith = Number(val)
        this.setServices(this.services)
    }

    setServices(list) {
        this.services = list
        let interval = window.setInterval(()=>{
            if(!this.drawing){
                this.drawing = list.length
                window.clearInterval(interval)
                this.initServices()
                this.drawFun()
            }
        },100)
    }

    drawFun() {
        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)
        this.services.forEach((one) => {
            this._drawServicesItem(one)
        })
    }

    initServices() {
        let length = this.services.length
        const dag = 4
        const r = this.r

        let dx, dy

        let list = []
        if (length % 2) {
            list.push({ dx: 0, dy: r })
            for (let i = 1; i <= (length - 1) / 2; i++) {
                dx = Math.sin(i * dag * Math.PI / 180) * r
                dy = Math.cos(i * dag * Math.PI / 180) * r

                list.push({ dx, dy })
                list.push({ dx: -dx, dy })
            }
        }else{
            for (let i = 0; i < length / 2; i++) {
                dx = Math.sin((i+.5) * dag * Math.PI / 180) * r
                dy = Math.cos((i+.5) * dag * Math.PI / 180) * r

                list.push({ dx, dy })
                list.push({ dx: -dx, dy })
            }
        }

        list = list.map(one => {
            return {
                x: one.dx + this.x,
                y: this.y - one.dy
            }
        }).sort((a, b) => {
            return a.x - b.x
        })

        this.services.forEach((one, k) => {
            one.position = list[k]
        })

    }

    _drawServicesItem(one) {
        if (one.logoUrl) {
            this.imgLoad(one.logoUrl).then(img => {
                this.drawing --

                let iw = img.width,
                    ih = img.height
                let dw = this.serviceWith * this._scale(),
                    dh = ih / iw * dw
                let x = one.position.x - dw / 2,
                    y = one.position.y - dh / 2
                this.context.drawImage(img, x, y, dw, dh)

                let textY = y - 20 * this._scale()
                let textX = x + dw / 2
                this.context.save()
                this.context.textAlign = 'center'
                this.context.font = '16px Avenir'
                this.context.fillStyle = '#fff'
                this.context.fillText(one.service, textX, textY)
            })
        }
    }

    imgLoad(url) {
        return new Promise((resolve, reject) => {
            let img = new Image()
            img.onload = () => {
                resolve(img)
            }
            img.onerror = (err) => {
                console.log(err)
                reject()
            }
            img.src = url
        })
    }
}

export default Services
