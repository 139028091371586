class Flag {
    constructor(road) {
        this.road = road
        this.msg = this.msg = new Proxy({},{
            get:(obj,prop)=>{
                return this.road.msg[prop]
            }
        })

        this.canvas = road.canvas
        this.context = road.context

        this.logoUrl = require('@/assets/center_v1_selected.png')
        this.systemNameUrl = require('@/assets/logo_home.png')

        this.logoImg = null
        this.systemNameImg = null
    }

    imgLoad(url) {
        return new Promise((resolve) => {
            let img = new Image()
            img.onload = () => {
                resolve(img)
            }
            img.src = url
        })
    }

    drawSystemName() {
        if (this.systemNameImg) {
            this.drawSystemNameFun()
        } else {
            this.imgLoad(this.systemNameUrl).then((img) => {
                this.systemNameImg = img
                this.drawSystemNameFun()
            })
        }
    }

    drawSystemNameFun() {
        let msg = this.msg

        let ch = this.canvas.height
        let iw = this.systemNameImg.width,
            ih = this.systemNameImg.height
        let dw = (msg.centerW - msg.routerWidth.bottom) * .8,
            dh = ih / iw * dw

        let x = 0,
            y = ch - dh - 30

        let el = this.systemNameImg
        this.context.drawImage(el, x, y, dw, dh)

    }

    drawLogo() {
        if (this.logoImg) {
            this.drawLogoFun()
        } else {
            this.imgLoad(this.logoUrl).then((img) => {
                this.logoImg = img
                this.drawLogoFun()
            })
        }
    }

    drawLogoFun() {
        let msg = this.msg

        let ch = this.canvas.height
        let iw = this.logoImg.width,
            ih = this.logoImg.height
        let dw = (msg.centerW - msg.routerWidth.bottom) * .2,
            dh = ih / iw * dw

        let x = 20,
            y = ch - dh - 40

        let el = this.logoImg
        this.context.drawImage(el, x, y, dw, dh)
    }
}

export default Flag
