<template>
    <div class="circle" v-bind:style="positionStyle">
        <div class="circle_box">
            <div class="total_box">
                <div>{{totalLabel}}</div>
                <div style="font-weight: bold">{{totalNum}}</div>
            </div>
        </div>
        <div class="wave_box">
            <div class="svg_box" :style="svgBoxStyle">
                <div class="svg_item" v-for="(waveImg,key) in svgPath" v-bind:key="key">
                    <img :src="waveImg">
                </div>
            </div>
            <div class="mark_box"></div>
        </div>
        <div class="btn_box" v-on:click="getAppToken">
            <img :src="btnImg" alt="">
        </div>
    </div>
</template>
<script type="text/javascript">
import waveImg from '@/assets/center_v1_wave.png'
import btnImg from '@/assets/center_v2_entrysystem.svg'
const boxWidth = 300

function scale() {
    const bw = 1920
    let tw = $(window).width()
    let scale = tw / bw
    return scale
}
export default {
    data() {
        return {
            btnImg,
            positionStyle: null,
            svgPath: [waveImg, waveImg, waveImg],
            svgBoxStyle: null,
            interval: null,
            totalNum: '',
            totalLabel: '',
            scale,
        }
    },
    created() {
        this.svgStyleFun()
    },
    props: {
        road: {
            type: Object,
        },
        app: {
            type: Object,
        }
    },
    watch: {
        app: {
            handler(val) {
                if (val && val.appId) {
                    this.getData()
                }
            },
            immediate: true,
        },
        'road.msg': {
            handler: function(val) {
                if (val) {
                    this.setPositionStyle()
                } else {
                    this.positionStyle = null
                }
            },
            immediate: true,
        },
        $route: {
            handler: function(route) {
                if (route.name == 'centerV2' || route.name == 'center') {
                    this.svgStyleFun()
                } else {
                    window.clearInterval(this.interval)
                }
            },
            immediate: true
        }
    },
    methods: {
        setPositionStyle() {
            let scale = this.scale()

            let msg = this.road && this.road.msg
            let { routerWidth } = msg

            let cw = window.$(window).width()
            let side = msg.centerW + routerWidth.bottom
            let dw = cw - side

            let obj = {
                left: side + (dw - boxWidth) / 2 + 'px',
                right: 'auto',
                bottom: '40px',
                transform: `scale(${scale})`,
                transformOrigin: '50% 100% 0'
            }

            this.positionStyle = obj;
        },
        svgStyleFun() {
            this.$nextTick(() => {
                var translateX = 0
                if (this.interval) {
                    window.clearInterval(this.interval)
                }
                this.interval = setInterval(() => {
                    var itemW = $('.svg_item').width()
                    var stap = itemW / 20
                    translateX += stap
                    this.setSvgBoxStyle(translateX)
                    setTimeout(() => {
                        if (translateX >= itemW) {
                            translateX = 0
                            this.setSvgBoxStyle(translateX, true)
                        }
                    }, 850)
                }, 900)
            })
        },
        setSvgBoxStyle(x, b) {
            let obj = {
                transform: `translateX(-${x}px)`
            }
            if (b) {
                obj.transition = 'unset'
            }
            this.svgBoxStyle = obj
        },
        getData() {
            let url = '/home/queryAppSpecialData'
            let params = {
                appId: this.app.appId
            }
            this.$http.post(url, params).then(data => {
                let { detail } = data
                this.totalNum = detail.data
                this.totalLabel = detail.title
            })
        },
        bankPage(url) {
            var id = 'bank_a'
            var a = null
            // 防止反复添加
            if (!document.getElementById(id)) {
                a = document.createElement('a')
                a.setAttribute('href', url)
                a.setAttribute('target', '_blank')
                a.setAttribute('id', id)
                document.body.appendChild(a)
            } else {
                a = document.getElementById(id)
                a.setAttribute('href', url)
            }
            a.style.display = 'none'
            a.click()
        },
        getAppToken() {
            let app = this.app
            console.log(app)

            if (!app.appId) {
                return false
            }

            if (/^(\/|#)/.test(app.appAddr)) {
                this.$router.push({ path: app.appAddr })
                return
            }

            if (!app.addr) {
                this.bankPage(app.appAddr)
                return
            }

            var acount = this.$store.state.info
            let url = '/app/info/getAppToken'
            let params = {
                userName: acount.userName,
                password: acount.password,
                appId: app.appId,
            }
            this.$http.post(url, params).then(({ detail }) => {
                this.$set(app, 'token', detail.token)
                let url = app.appAddr
                console.log(app.appAddr)
                if (/\?/.test(url)) {
                    url += '&token=' + app.token
                } else {
                    url += '?token=' + app.token
                }
                console.log(url)
                this.bankPage(url)
            })
        },
    },
}

</script>
<style type="text/css" lang="scss" scoped="circle">
$itemWidth:300px;

.circle {
    position: fixed;
    right: 100px;
    bottom: 100px;

    .wave_box {
        position: absolute;
        height: $itemWidth;
        width: $itemWidth;
        border-radius: 50%;
        border: 3px solid transparent;
        overflow: hidden;
        top: 0;

        .svg_box {
            overflow: hidden;
            position: absolute;
            bottom: 45%;
            white-space: nowrap;
            transition: all .8s;
            text-align: right;

            .svg_item {
                height: 80px;
                display: inline-block;

                img {
                    height: 80px;
                }
            }
        }

        .mark_box {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            width: 100px;
            background: linear-gradient(to left, rgb(2, 6, 12) 40%, rgba(255, 255, 255, 0));
        }
    }

    .circle_box {
        position: relative;
        height: $itemWidth;
        width: $itemWidth;
        border-radius: 50%;
        border: 3px solid white;

        >div {
            position: absolute;
            left: 115px;
            top: 115px;
        }

        .img_box {
            position: relative;
            width: 70px;
            height: 70px;
            text-align: center;

            .img_text {
                height: 20px;
                line-height: 20px;
                color: white;
                position: relative;
                top: -6px;
                font-size: 16px;
            }

            .img {
                width: 50px;
                height: 50px;
            }
        }

        .total_box {
            position: absolute;
            width: 80%;
            top: 47%;
            left: -10%;
            bottom: 20px;
            color: white;
            font-size: 20px;
            text-align: left;
            background-color: #02060c;
            padding: 30px 0px;

            >div {
                position: relative;
                z-index: 100;
                text-align: right;
                transform: translateX(20%);

                &:nth-of-type(1) {
                    font-size: 24px;
                }

                &:nth-of-type(2) {
                    margin-top: 10px;
                    font-size: 28px;
                }
            }
        }
    }

    .btn_box {
        position: absolute;
        top: 50%;
        width: 150px;
        height: 150px;
        transform: translateX(-36%);
        border-radius: 50%;
        overflow: hidden;
        z-index: 200;

        img {
            cursor: pointer;
            width: 100%;
            height: 100%;
        }
    }

}

</style>
