<template>
    <div class="center_v2_bg">
        <canvas ref="bgCanvas"></canvas>
        <canvas ref="servicesCanvas"></canvas>
        <canvas ref="carCanvas"></canvas>
        <apps ref="apps" v-if="car && car.position" :apps="appItems" :subPosition="car.subPosition" :position="car.position" @appSelect="appSelect"></apps>
        <circle-system :road="road" :app="app"></circle-system>
        <el-popover placement="bottom" title="参数设置" trigger="click" width="300" v-if="false">
            <el-form label-width="90px">
                <el-form-item label="车宽">
                    <el-input placeholder="相对屏幕宽度比例，如 0.64" v-model="widthScale"></el-input>
                </el-form-item>
                <el-form-item label="serviceWith">
                    <el-input placeholder="服务项图片大小" v-model="serviceWith"></el-input>
                </el-form-item>
            </el-form>
            <i class="el-icon-setting set_button" slot="reference"></i>
        </el-popover>
    </div>
</template>
<script>
import Earth from './Earth.js'
import Road from './Road.js'
import MapClass from './Map.js'
import Flag from './Flag.js'
import Speed from './Speed.js'

import Car from './Car.js'

import Services from './Services.js'

import circleSystem from './circle.vue'
import apps from './apps.vue'

const showEndList = [
    '智慧运营',
    '智慧充电',
    '智慧约车',
    '智能调度',
    '安全在线',
    '智慧汽修',
    '智慧场站'
]
export default {
    components: {
        circleSystem,
        apps
    },
    data() {
        return {
            canvas: null,
            canvasCar: null,
            canvasServices: null,

            earth: null,
            road: null,
            speed: null,
            car: null,
            services: null,

            appItems: null,
            app: null,
            topServicesApp: null,

            interval: null,

            //绘制参数
            widthScale: 0.63,
            serviceWith: 55,
        }
    },
    watch: {
        widthScale: function(val) {
            this.car.setWidthScale(val)
        },
        serviceWith: function(val) {
            this.services.setServiceWith(val)
        },
        $route: {
            handler: function(route) {
                if (route.name == 'center' || route.name == 'centerV2') {
                    this.$nextTick(() => {
                        window.clearInterval(this.interval)
                        this.interval = window.setInterval(() => {
                            if (this.speed) {
                                window.clearInterval(this.interval)
                                this.drawSpeed()
                            }
                        }, 100)
                    })
                } else {
                    // 防止内存泄漏
                    window.clearInterval(this.interval)
                }
            },
            immediate: true
        }
    },
    beforeDestroy() {
        window.clearInterval(this.interval)
    },
    created() {
        this.getAppItems()
    },
    mounted() {
        this.$nextTick(() => {
            this.init()
            this.drawFun()
        })
        window.$(window).on('resize', () => {
            this.$nextTick(() => {
                this.initStyle()
                this.reDraw()
            })
        })
    },
    methods: {
        init() {
            this.canvas = this.$refs.bgCanvas
            this.canvasCar = this.$refs.carCanvas
            this.canvasServices = this.$refs.servicesCanvas

            this.initStyle()

            this.earth = new Earth(this.canvas)
            this.road = new Road(this.earth)
            this.map = new MapClass(this.road)
            this.flag = new Flag(this.road)
            this.speed = new Speed(this.road)

            this.car = new Car(this.canvasCar, { earth: this.earth, road: this.road })

            this.services = new Services(this.canvasServices, this.earth)
        },
        initStyle() {
            let width = $(this.canvas).width(),
                height = $(this.canvas).height()

            this.canvas.width = width
            this.canvas.height = height

            this.canvasCar.width = width
            this.canvasCar.height = height

            this.canvasServices.width = width
            this.canvasServices.height = height
        },
        reDraw() {
            this.canvas.getContext('2d').clearRect(0, 0, this.canvas.width, this.canvas.height)
            this.canvasCar.getContext('2d').clearRect(0, 0, this.canvasCar.width, this.canvasCar.height)
            this.canvasServices.getContext('2d').clearRect(0, 0, this.canvasServices.width, this.canvasServices.height)
            this.earth.init()
            this.road.initMsg()
            this.road.setRouter()
            this.services.init()
            this.drawFun()
            this.services.initServices()
            this.services.drawFun()
            if (showEndList.includes(this.app.cName)) {
                this.car.showEmpennage()
            }
        },
        drawFun(b = true) {
            this.earth.drawBgLinght()
            this.earth.drawHalo()
            this.earth.drawEarth()
            this.road.drawRouter()
            this.speed.setSpeed()
            this.earth.drawLightShadow()

            this.map.drawMap().then(() => {
                this.flag.drawSystemName()
                this.flag.drawLogo()
            })
            if (b) {
                this.car.getPostion()
            }
        },
        drawSpeed() {
            this.interval = window.setInterval(() => {
                this.canvas.getContext('2d').clearRect(0, 0, this.canvas.width, this.canvas.height)
                this.drawFun(false)
            }, this.speed.msg.speed)
        },
        getAppItems() {
            let url = '/app/info/queryUserApp'
            this.$http.post(url).then(({ detail }) => {
                this.appItems = detail
            })
        },
        appSelect(app, hidden) {
            if (app && app.appServices) {
                if (!showEndList.includes(app.cName)) {
                    this.topServicesApp = app
                }
                this.services.setServices(app.appServices)
            }
            if (hidden) {
                this.car.hiddenEmpennage()
                this.$refs.apps.setAppKey(this.topServicesApp)
                this.app = this.topServicesApp
                this.services.setServices(this.app.appServices)
            } else if (showEndList.includes(app.cName)) {
                this.car.showEmpennage()
                this.app = app
            } else {
                this.car.hiddenEmpennage()
                this.app = app
            }
        }
    },
}

</script>
<style lang="scss" scoped="center_v2_bg">
.center_v2_bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #02060c;

    canvas {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    .app_box {
        .app {
            cursor: pointer;
            color: #fff;
            position: absolute;
            transform: translate(-50%, -50%);
            text-align: center;

            img {
                width: 10vh;
            }
        }
    }

    .set_button {
        position: absolute;
        top: 60px;
        left: 10px;
        font-size: 35px;
        color: #fff;
        cursor:pointer;
    }
}

</style>
