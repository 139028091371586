class Utils {
    constructor() {
        this.baseWidth = 1920
        this.baseHeight = 979
    }

    _scale() {
        const bw = this.baseWidth
        let tw = $(window).width()
        let scale = tw / bw
        return scale
    }

    _scaleByHeight() {
        const baseWidth = this.baseWidth
        const baseHeight = this.baseHeight

        let cw = $(window).width()
        let ch = $(window).height()

        return (ch / cw) / (baseHeight / baseWidth)
    }
}

export default Utils