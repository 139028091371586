class Earth {
    constructor(canvas) {
        this.canvas = canvas
        this.context = canvas.getContext('2d')

        this.init()
    }

    init(){
        this.x= this.canvas.width / 2
        this.y= this.canvas.width * 1.2
        this.r= this.y * .925
    }

    drawEarth() {
        this.context.save()
        this.context.beginPath()
        this.context.arc(this.x, this.y, this.r, 0, Math.PI * 2)
        this.context.closePath()
        this.context.fillStyle = '#02060c'
        this.context.fill()
        this.context.restore()
    }

    drawBgLinght(){
        let r = this.canvas.width / 5*2
        let x = this.canvas.width / 2
        let y = r * .8

        this.context.save()
        this.context.beginPath()
        this.context.arc(x,y,r,0,Math.PI*2)

        let grd = this.context.createRadialGradient(x,y,0,x,y,r,)
        grd.addColorStop(0,'rgb(11,86,197,8)')
        grd.addColorStop(.5,'rgb(11,86,197,.4)')
        grd.addColorStop(1,'rgb(11,86,197,0)')
        this.context.fillStyle = grd

        this.context.fill()
        this.context.restore()

    }

    drawHalo() {
        let r = this.r * 0.85
        let x = this.x
        let y = this.y - this.r * 0.17

        this.context.save()

        this.context.beginPath()
        this.context.arc(x, y, r, 0, Math.PI * 2)
        this.context.closePath()

        let grd = this.context.createRadialGradient(x, y, 0, x, y, r)
        grd.addColorStop(0, 'rgba(255,255,255,1)')
        grd.addColorStop(.95, 'rgba(255,255,255,.9)')
        grd.addColorStop(.99, 'rgba(255,255,255,.05)')
        grd.addColorStop(1, 'rgba(255,255,255,.0)')

        this.context.fillStyle = grd
        this.context.fill()

        this.context.restore()
    }

    drawLightShadow() {
        let r1 = this.r,
            r2 = this.r * 0.85,
            d = this.r * 0.17
        let b = ((r2 * r2) - (r1 * r1) - (d * d) + (2 * d * r1)) / (2 * d)

        this.context.save()
        this.context.beginPath()

        this.context.rect(0,(this.y-this.r),this.canvas.width,b)
        this.context.clip()

        this.context.beginPath()
        this.context.arc(this.x, this.y, this.r, 0, Math.PI*2)

        let grd = this.context.createLinearGradient(0,0,0,b+(this.y-this.r))
        grd.addColorStop(0,'rgba(110,168,255,8)')
        grd.addColorStop(.3,'rgba(110,168,255,.6)')
        grd.addColorStop(.6,'rgba(3,56,136,.4)')
        grd.addColorStop(1,'rgba(3,56,136,.0)')
        this.context.fillStyle = grd
        // this.context.stroke()
        this.context.fill()

        this.context.restore()


    }
}

export default Earth
