<template>
    <div class="home">
        <main-com v-if="version==0"></main-com>
        <main-com-v1 v-else></main-com-v1>
        <div class="top_out" v-on:click="logout">
            <img src="../assets/operate/nav_icon_logout.png" />
        </div>
        <el-tooltip content="切换版本 , 正式版要去掉" v-if="false">
            <div class="top_charge" v-on:click="versionChange">
                <i class="el-icon-refresh"></i>
            </div>
        </el-tooltip>
    </div>
</template>
<script>
import mainCom from '@/components/center/v2/main'
import mainComV1 from '@/components/center/v2.1/main'
export default {
    data() {
        return {
            version: 0,
        }
    },
    components: {
        mainCom,
        mainComV1
    },
    methods: {
        logout() {
            this.$confirm('您确定退出该系统？', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                let url = '/logout'
                this.$http.post(url).then(() => {
                    this.$util.removeStor('token')
                    this.$store.state.info = null
                    window.location.reload()
                    this.$router.push({ name: 'login' })
                })
            })
        },
        versionChange() {
            this.version = this.version == 0 ? 1 : 0
        },
    }
}

</script>
<style lang="scss" scoped="home_v2">
.home {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .top_out {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
        z-index: 4000;
    }

    .top_charge {
        position: absolute;
        left: 10px;
        top: 10px;
        cursor: pointer;
        z-index: 4000;
        color: #fff;
        font-size: 35px;
    }
}

</style>
