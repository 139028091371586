import Utils from './Utils.js'

class Car extends Utils {
    constructor(canvas, { earth, road }) {
        super()
        this.canvas = canvas
        this.context = canvas.getContext('2d')

        this.earth = earth
        this.road = road

        this.baseCarUrl = require('@/assets/center_v2_car.png')
        this.baseCarImg = null

        this.appPositionUrl = require('@/assets/center_v2_car_position.png')
        this.appPositionImg = null

        this.endLeftUrl = require('@/assets/center_v2_end_l.png')
        this.endLeftImg = null
        this.endRightUrl = require('@/assets/center_v2_end_r.png')
        this.endRightImg = null

        this.position = []
        this.subPosition = []

        this.widthScale = 0.5

    }

    setWidthScale(val, callback) {
        let xl = this.canvas.width
        let yl = this.canvas.height

        this.widthScale = Number(val)
        this.context.clearRect(0, 0, xl, yl)
        this.getPostion(callback)
    }

    imgLoad(url) {
        return new Promise((resolve, reject) => {
            let img = new Image()
            img.onload = () => {
                resolve(img)
            }
            img.onerror = (err) => {
                console.log(err)
                reject()
            }
            img.src = url
        })
    }

    getPostion(callback) {
        this.drawCar('appPosition', () => {
            let imgData = this.context.getImageData(0, 0, this.canvas.width, this.canvas.height)
            let data = imgData.data

            let xl = this.canvas.width
            let yl = this.canvas.height


            let l = []
            let subList = []
            for (let y = 0; y < yl; y++) {
                for (let x = 0; x < xl; x++) {
                    let ind = (y * xl + x) * 4;
                    let r = data[ind];
                    let g = data[ind + 1];
                    let b = data[ind + 2];
                    // let a = data[ind + 3];

                    if (r != g || r != b || g != b) {
                        if (subList.length == 0) {
                            subList.push({ x, y })
                        } else {
                            let has = subList.some(one => {
                                if (x - one.x < 10 && y - one.y < 10) {
                                    return true
                                } else {
                                    return false
                                }
                            })
                            if (!has) {
                                subList.push({ x, y })
                            }
                        }
                    }

                    if ((r == g && g == b) && r == 255) {
                        if (l.length == 0) {
                            l.push({ x, y })
                        } else {
                            let endPot = l[l.length - 1]
                            if (x - endPot.x > 2 || y - endPot.y > 2) {
                                l.push({ x, y })
                            }
                        }
                    }

                }
            }
            this.position = l
            this.subPosition = subList.sort((a, b) => {
                return a.x - b.x
            })
            this.context.clearRect(0, 0, xl, yl)
            callback ? callback() : this.drawCar()
        })
    }

    drawCar(type = 'baseCar', callback) {
        if (this[type + 'Img']) {
            this.drawCarFun(type)
            callback && callback()
        } else {
            this.imgLoad(this[type + 'Url']).then(img => {
                this[type + 'Img'] = img
                this.drawCarFun(type)
                callback && callback()
            })
        }
    }

    drawCarFun(type = 'baseCar') {

        let baseSscale = this.widthScale * this._scaleByHeight()
        let scale = baseSscale > .8 ? .8 : baseSscale

        let el = this[type + 'Img']

        let ch = this.canvas.height,
            cw = this.canvas.width
        let iw = el.width,
            ih = el.height
        let dw = cw * scale,
            dh = ih / iw * dw

        let x = (cw - dw) / 2 + 1,
            y = (ch - dh) / 2 * (scale / baseSscale)

        this.context.drawImage(el, x, y, dw, dh)
    }

    showSpary() {
        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)
        this.drawCar()
        this.spray()
    }

    hiddenSpary() {
        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)
        this.drawCar()
    }

    spray() {
        var dofun = (type) => {
            let imgName = `end${type}Img`
            let imgUrl = `end${type}Url`
            if (this[imgName]) {
                this.sprayFun(type)
            } else {
                this.imgLoad(this[imgUrl]).then(img => {
                    this[imgName] = img
                    this.sprayFun(type)
                })
            }
        }
        dofun('Left')
        dofun('Right')
    }

    sprayFun(type) {
        let imgName = `end${type}Img`
        let el = this[imgName]

        let iw = el.width,
            ih = el.height
        let dw = iw * this._scale(),
            dh = ih / iw * dw

        let pl = type == 'Left' ? this.subPosition.slice(0, 2) : this.subPosition.slice(2)
        pl.forEach(one => {
            let x = type == 'Left' ? one.x - dw / 3 * 2 : one.x - dw / 3,
                y = one.y

            this.context.drawImage(el, x, y, dw, dh)
        })
    }
}

export default Car
