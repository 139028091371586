import Utils from './Utils.js'

class Road extends Utils {
    constructor(earth) {
        super()
        this.earth = earth

        this.canvas = earth.canvas
        this.context = earth.context

        this.initMsg()
    }

    initMsg() {
        let width = this.canvas.width
        let height = this.canvas.height
        const routerWidth = {
            step: 40 * this._scale(),
            top: 100*this._scale(),
            bottom: 360*this._scale(),
        }
        let top = this.earth.y * 0.09

        this.msg = {
            top,
            width,
            height,
            centerW: width / 2,
            routerWidth,
        }
    }

    drawRouter() {
        if (!this.lines || this.lines.length != 4) {
            this.setRouter()
        }
        this.lines.forEach(one => {
            this._line(one)
        })
    }

    setRouter() {
        this.lines = []
        let routerWidth = this.msg.routerWidth

        let y1 = this.msg.top,
            y2 = this.msg.height
        let line1 = {
            x1: this.msg.centerW - routerWidth.top,
            x2: this.msg.centerW - routerWidth.bottom,
            color: [{ s: 0, v: 'rgba(125,125,125,0)' }, { s: .15, v: 'rgba(125,125,125,.3)' }, { s: 1, v: 'rgba(125,125,125,.3)' }]
        }
        let line2 = {
            x1: this.msg.centerW + routerWidth.top,
            x2: this.msg.centerW + routerWidth.bottom,
            color: [{ s: 0, v: 'rgba(125,125,125,0)' }, { s: .15, v: 'rgba(125,125,125,.3)' }, { s: 1, v: 'rgba(125,125,125,.3)' }]
        }
        let line3 = {
            x1: this.msg.centerW - routerWidth.top + routerWidth.step,
            x2: this.msg.centerW - routerWidth.bottom + (routerWidth.bottom * routerWidth.step / (routerWidth.top)),
            color: [{ s: 0, v: 'rgba(17,47,93,0)' }, { s: .15, v: 'rgba(17,47,93,.4)' }, { s: 1, v: 'rgba(17,47,93,.4)' }]
        }
        let line4 = {
            x1: this.msg.centerW + routerWidth.top - routerWidth.step,
            x2: this.msg.centerW + routerWidth.bottom - (routerWidth.bottom * routerWidth.step / (routerWidth.top)),
            color: [{ s: 0, v: 'rgba(17,47,93,0)' }, { s: .15, v: 'rgba(17,47,93,.4)' }, { s: 1, v: 'rgba(17,47,93,.4)' }]
        }

        this.lines.push(this._getGrd({ ...line1, y1, y2 }))
        this.lines.push(this._getGrd({ ...line2, y1, y2 }))
        this.lines.push(this._getGrd({ ...line3, y1, y2 }))
        this.lines.push(this._getGrd({ ...line4, y1, y2 }))
        // this.setRouter_Line()
    }

    _getGrd({ x1, y1, x2, y2, color }) {
        var grd = this.context.createLinearGradient(x1, y1, x2, y2);
        color = color.forEach(one => {
            grd.addColorStop(one.s, one.v);
        })
        color = grd
        return ({ x1, y1, x2, y2, color })
    }

    _line({ x1, y1, x2, y2, color }, d = 2) {
        this.context.save()
        this.context.beginPath()
        this.context.moveTo(x1-d, y1)
        this.context.lineTo(x2-d, y2)
        this.context.lineTo(x2 + d, y2)
        this.context.lineTo(x1 + d, y1)
        if (color) {
            this.context.fillStyle = color
        }
        this.context.fill()
        this.context.restore()
    }
}

export default Road
