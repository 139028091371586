<template>
    <div class="center_v2_app_box" v-if="position&&position.length">
        <div class="app" v-for="(one,key) in appList" :key="one.x+one.y" :class="{'alive':appKey == key,'disable':one.app.disable}" :style="getStyle(one)" v-on:click="appClick(one,key)">
            <img :src="appKey==key?one.logoAlive:one.logo" alt="">
            <p>{{one.name}}</p>
        </div>
        <div v-show="appKey==4">
            <div class="app sub_app" v-for="(one,key) in subAppList" :key="one.x+one.y" :class="{'alive':subAppKey == key,'disable':one.app.disable}" :style="getStyle(one)" v-on:click="subAppClick(one,key)">
                <img :src="subAppKey==key?one.logoAlive:one.logo" alt="">
                <p>{{one.name}}</p>
            </div>
        </div>
    </div>
</template>
<script>
import logo from '@/assets/center_v1_selected.png'

const appsList = [
    { name: '实时监控', logo: 'monitoring' },
    { name: '智慧驾管', logo: 'driver' },
    { name: '数据中心', logo: 'data' },
    { name: '智能车管', logo: 'cartube' },
    { name: '智慧运营', logo: 'option' }
]
const subAppsList = [
    { name: '智慧充电', logo: 'charge' },
    { name: '安全在线', logo: 'education' },
    { name: '智慧汽修', logo: 'repair' },
    { name: '智慧场站', logo: 'station' }
]
export default {
    props: {
        position: {
            type: Array,
            default: () => []
        },
        subPosition: {
            type: Array,
            default: () => []
        },
        apps: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            logo,
            appKey: 0,
            subAppKey: -1,
        }
    },
    watch: {
        apps: function(val) {
            if (val.length) {
                this.$nextTick(() => {
                    this.$emit('appSelect', this.appList[this.appKey].app)
                })
            }
        },
        appKey: function(val) {
            if (val != 4) {
                this.subAppKey = -1
            }
        },
    },
    computed: {
        appList: function() {
            let list = appsList.map((one, key) => {
                let app = { cName: one.name, disable: true }
                if (one.name == '智慧运营') {
                    app.disable = false
                }
                if (this.apps) {
                    this.apps.forEach(item => {
                        if (item.cName == one.name) {
                            app = item
                            app.disable = false
                        }
                    })
                }
                return {
                    logo: require(`@/assets/apps/${one.logo}.png`),
                    logoAlive: require(`@/assets/apps/${one.logo}_alive.png`),
                    name: one.name,
                    ...this.position[key] || {},
                    app,
                }
            })
            return list
        },
        subAppList: function() {
            let list = subAppsList.map((one, key) => {
                let app = { cName: one.name, disable: true }
                if (this.apps) {
                    this.apps.forEach(item => {
                        if (item.cName == one.name) {
                            app = item
                            app.disable = false
                        }
                    })
                }
                return {
                    logo: require(`@/assets/apps/${one.logo}.png`),
                    logoAlive: require(`@/assets/apps/${one.logo}_alive.png`),
                    name: one.name,
                    ...this.subPosition[key] || {},
                    app,
                }
            })
            return list
        },
    },
    methods: {
        getStyle(one) {
            return {
                top: one.y + 'px',
                left: one.x + 'px'
            }
        },
        setAppKey(app) {
            console.log(app)
            appsList.forEach((one, key) => {
                if (one.name == app.cName) {
                    this.appKey = key
                }
            })
        },
        appClick(item, key) {
            this.appList.forEach(one => {
                if (one.name == item.name) {
                    one.selected = true
                } else {
                    one.selected = false
                }
            })
            if (key == 4 && this.appKey == key) {
                this.appKey = -1
                this.$emit('appSelect', item.app, true)
            } else if (item.app.appId || key == 4) {
                this.$emit('appSelect', item.app)
                this.appKey = key
            }
        },
        subAppClick(item, key) {
            if (item.app.disable) {
                return
            }
            this.$emit('appSelect', item.app)
            this.subAppKey = key
        }
    }
}

</script>
<style lang="scss" scoped="center_v2_app_box">
.center_v2_app_box {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    letter-spacing: .12vw;

    .app {
        cursor: pointer;
        color: #fff;
        position: absolute;
        transform: translate(-50%, -50%);
        text-align: center;
        font-size: 1vw;

        &.alive {
            color: #43d0ff;
        }

        &.disable {
            cursor: inherit;
            opacity: .5;
        }

        img {
            margin-bottom: .2vh;
            width: 5vw;
        }
    }

    .sub_app {

        &:nth-of-type(1),
        &:nth-of-type(2) {
            transform: translate(-125%, 60%);
        }

        &:nth-of-type(3),
        &:nth-of-type(4) {
            transform: translate(25%, 60%);
        }

        img {
            margin-bottom: .2vh;
            width: 3.8vw;
        }
    }
}

</style>
